/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
import { CampaignFragmentDoc } from './CampaignFragments.generated';
import { SmartAddressFragmentDoc, FileFragmentDoc, CustomAddressFragmentDoc } from './CoreFragments.generated';
export const MyProfileFragmentDoc = gql`
    fragment MyProfile on MyProfile {
  id
  uuid
  name
  surname
  companyName
  email
  otherPhones
  phone
  preferedComunication
  notificationPolicy
  isLogged
  vocative
  activeCampaigns {
    ...Campaign
  }
  role
  firstCompleteAddress {
    ...SmartAddress
  }
  backofficeProfile {
    id
  }
  customerZoneIntroSeenAt
  passwordSet
}
    ${CampaignFragmentDoc}
${SmartAddressFragmentDoc}`;
export const ProfileUserFragmentDoc = gql`
    fragment ProfileUser on ProfileUser {
  email
  name
}
    `;
export const AdminProfileFragmentDoc = gql`
    fragment AdminProfile on AdminProfile {
  id
  userId
  uuid
  name
  username
  email
  role
}
    `;