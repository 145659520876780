/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
export const FileFragmentDoc = gql`
    fragment File on File {
  id
  uuid
  type
  extension
  namespace
  name
}
    `;
export const ImageFragmentDoc = gql`
    fragment Image on Image {
  id
  name
  uuid
  type
  createdAt
  extension
  height
  namespace
  size
  width
}
    `;
export const CodebookFragmentDoc = gql`
    fragment Codebook on Codebook {
  id
  label
}
    `;
export const MoneyFragmentDoc = gql`
    fragment Money on Money {
  currency
  amount
}
    `;
export const SmartAddressFragmentDoc = gql`
    fragment SmartAddress on SmartAddress {
  id
  street
  descriptiveNumber
  orientationNumber
  orientationNumberCharacter
  city
  cityPart
  level
  zip
  region
  district
  gps {
    latitude
    longitude
  }
}
    `;
export const CustomAddressFragmentDoc = gql`
    fragment CustomAddress on CustomAddress {
  address
  zipCode
  city
}
    `;